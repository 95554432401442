import { apiAction } from '.';

export const DiscoveryTypes = {
  GET_INBOUND_VALUE: 'GET_INBOUND_VALUE',
  GET_DISCOVERY_LIST: 'GET_DISCOVERY_LIST',
  CREATE_DISCOVERY: 'CREATE_DISCOVERY',
  CLOSE_DISCOVERY: 'CLOSE_DISCOVERY',
  CREATE_HOUSEHOLD_FROM_DISCOVERY: 'CREATE_HOUSEHOLD_FROM_DISCOVERY',
  DELETE_DISCOVERY: 'DELETE_DISCOVERY',
  // UPDATE_INBOUND_VALUE: 'UPDATE_INBOUND_VALUE',
};

//ACTION TO SET WHETHER INBOUND LINK IN ENABLED IN STORE <object>
export const setInboundValue = (inboundValue) => ({
  type: DiscoveryTypes.GET_INBOUND_VALUE,
  payload: inboundValue,
});

//API CALL TO GET WHETHER INBOUND LINK IN ENABLED
export const getInboundValue = () => {
  return apiAction({
    path: `/interview/enable_lead`,
    method: 'GET',
    onSuccess: setInboundValue,
    label: DiscoveryTypes.GET_INBOUND_VALUE,
  });
};

//ACTION TO SET LIST OF ALL DISCOVERY INSTANCES IN STORE <array>
export const setDiscoveryList = (discoveryList) => ({
  type: DiscoveryTypes.GET_DISCOVERY_LIST,
  payload: discoveryList,
});

//API CALL TO LIST OF ALL DISCOVERY INSTANCES W/ OPTIONAL SORT PARAMS
export const getDiscoveryList = (
  offset = 0,
  is_lead = '',
  currency = '',
  status = '',
  lead_advisor = '',
  dual_discovery_type = ''
) => {
  return apiAction({
    path: `/interview?offset=${offset}&is_lead=${is_lead}&currency=${currency}&status=${status}${
      lead_advisor !== '' ? `&lead_advisor=${lead_advisor}` : ``
    }${
      dual_discovery_type !== ''
        ? `&dual_discovery_type=${dual_discovery_type}`
        : ``
    }`,
    method: 'GET',
    onSuccess: setDiscoveryList,
    label: DiscoveryTypes.GET_DISCOVERY_LIST,
  });
};

//ACTION THAT SETS RESPONSE FROM CREATED DISCOVERY IN STORE <object>
export const setCreatedDiscovery = (discovery) => ({
  type: DiscoveryTypes.CREATE_DISCOVERY,
  payload: discovery,
});

//API CALL TO DELETE DISCOVERY INSTANCE
export const createDiscovery = (data) => {
  return apiAction({
    path: `/interview`,
    method: 'POST',
    data: data,
    onSuccess: setCreatedDiscovery,
    label: DiscoveryTypes.CREATE_DISCOVERY,
  });
};

//API CALL TO CLOSE DISCOVERY INSTANCE BY ID
export const closeDiscovery = (id) => {
  return apiAction({
    path: `/interview/${id}/close`,
    method: 'GET',
    onSuccess: getDiscoveryList,
    ignoreResponse: true,
    successMessage: 'Discovery Closed',
    label: DiscoveryTypes.CLOSE_DISCOVERY,
  });
};

//API CALL TO CREATE DISCOVERY INSTANCE BY ID
export const createHouseholdFromDiscovery = (id) => {
  return apiAction({
    path: `/interview/${id}/generate`,
    method: 'GET',
    onSuccess: getDiscoveryList,
    ignoreResponse: true,
    successMessage: 'Household Created',
    label: DiscoveryTypes.CREATE_HOUSEHOLD_FROM_DISCOVERY,
  });
};

//API CALL TO DELETE DISCOVERY INSTANCE BY ID
export const deleteDiscovery = (id) => {
  return apiAction({
    path: `/interview/${id}`,
    method: 'DELETE',
    onSuccess: getDiscoveryList,
    ignoreResponse: true,
    successMessage: 'Deleted Discovery',
    label: DiscoveryTypes.DELETE_DISCOVERY,
  });
};

//NOT BEING USED - REPLACED IN USER PREFERENCES CUSTOM
// export const updateInboundValue = (value) => {
//   let display;
//   value ? (display = 'Active') : (display = 'Inactive');
//   return apiAction({
//     path: `/interview/enable_lead`,
//     method: 'POST',
//     data: `checked=${value}`,
//     onSuccess: setInboundValue,
//     successMessage: `Inbound Link ${display}`,
//     label: DiscoveryTypes.UPDATE_INBOUND_VALUE,
//   });
// };
